import { Box, Grid, Stack, Typography } from '@mui/material';
import { ColoredInfo } from '../../components/ColoredInfo/index.js';
import React from 'react';
import moment from 'moment';
import { arrayType } from '../../views/PipeTally/Data/PipeTallyData.jsx';

export const Header = ({ objPipeTally }) => {
    console.log("objPipeTally", objPipeTally)
    return (
        <Stack
            direction={'row'}
            spacing={3}
            justifyContent={'space-between'}
            p={2}
            sx={{
                mx: '30px',
                my: '10px',
                backgroundColor: 'common.white',
                position: 'relative',
                borderRadius: '15px',
            }}
        >

            <Box>
                <Typography
                    fontWeight={700}

                    mb={2}
                    sx={{
                        borderColor: 'grey.light',
                    }}
                >
                    Pipe Tally Details
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Typography mb={1}>Pipe Profile Description</Typography>
                        <Typography style={{ fontWeight: 700, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{objPipeTally.pipeProfile.description && objPipeTally.pipeProfile.description != "" ? objPipeTally.pipeProfile.description : "-"}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography mb={1}>Type</Typography>
                        <Typography style={{ fontWeight: 700, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{arrayType.find(obj => obj.id == objPipeTally.type)?.value}</Typography>
                    </Grid>
                    <Grid item xs={2.5}>
                        <Typography mb={1}>Purchase order</Typography>
                        <Typography style={{ fontWeight: 700, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{objPipeTally.purchaseOrder && objPipeTally.purchaseOrder != "" ? objPipeTally.purchaseOrder : "-"}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography mb={1}>Site Location</Typography>
                        <Typography style={{ fontWeight: 700, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{objPipeTally.location.siteName && objPipeTally.location.siteName != "" ? objPipeTally.location.siteName : "-"}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography mb={1}>Pipe name</Typography>
                        <Typography style={{ fontWeight: 700, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{objPipeTally.pipeProfile.connectionName && objPipeTally.pipeProfile.connectionName != "" ? objPipeTally.pipeProfile.connectionName : "-"}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography mb={1}>Updated</Typography>
                        <Typography style={{ fontWeight: 700, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{objPipeTally.updatedAt && objPipeTally.updatedAt != "" ? moment(objPipeTally.updatedAt).format("MM-DD-YYYY hh:mm a") : "-"}</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Typography
                    fontWeight={700}

                    mb={1}
                    sx={{
                        borderColor: 'grey.light',
                        whiteSpace: 'nowrap',
                    }}
                >
                    String Totals
                </Typography>
                <Box mb={2}>
                    <Typography fontWeight={700} fontSize={22}>{objPipeTally.pipes
                        ? parseInt(
                            objPipeTally.pipes.reduce((total, obj) => {
                                const jntValue = parseFloat(obj.length);
                                return !isNaN(jntValue) ? total + jntValue : total;
                            }, 0)
                        )
                        : 0}</Typography>
                    <Typography whiteSpace='nowrap'>Total Footage</Typography>
                </Box>
                <Box>
                    <Typography fontWeight={700} fontSize={22}>{objPipeTally.quantity && objPipeTally.quantity != "" ? objPipeTally.quantity : "-"}</Typography>
                    <Typography whiteSpace='nowrap'>Total Joints</Typography>
                </Box>
            </Box>
            <Box >
                <Box mb={3}>
                    <Typography fontWeight={700} color="#262B40">String Health</Typography>
                    <Typography fontSize={13} color="#8F98AB">Overview</Typography>
                </Box>
                <Stack direction={'row'} spacing={3}>

                    <ColoredInfo
                        color={'#36B368'}
                        title={'AAA'}
                        subTitle={'Over 100%'}
                        value={objPipeTally.totalAAA}
                    />
                    <ColoredInfo
                        color={'#0FB5FF'}
                        title={'AA'}
                        subTitle={'99.9% - 95%'}
                        value={objPipeTally.totalAA}
                    />
                    <ColoredInfo
                        color={'#FFAA50'}
                        title={'A'}
                        subTitle={'94.9% - 90%'}
                        value={objPipeTally.totalA}
                    />
                    <ColoredInfo
                        color={'#E83880'}
                        title={'EOL'}
                        subTitle={'89.9% - 80%'}
                        value={objPipeTally.totalEol}
                    />
                    <ColoredInfo
                        color={'#262B40'}
                        title={'DBR/Downgrade'}
                        subTitle={'DBR – 79.99% Lower'}
                        value={objPipeTally.totalDowngrade}
                    />
                </Stack>
            </Box>
        </Stack>
    );
};
