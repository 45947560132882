import {Stack, Typography } from '@mui/material';
import {PipeTallyColorStatus } from '../../../components/ColorStatus';

export const arrayType = [
    { id: "0", value: "Outbound Tally" },
    { id: "1", value: "Inbound Tally" },
]

export const arrayStatus = [
    { id: "3", value: "New" },
    { id: "0", value: "In Progress" },
    { id: "1", value: "On pause" },
    { id: "2", value: "Completed" },
]

export const columns = [
    {
        field: 'tallyNo',
        headerName: 'Tally ID',
        width: 100,
    },
    {
        field: 'title',
        headerName: 'Tally Title',
        flex: 1,
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 120,
        renderCell: ({ value }) => (
            <Stack width={'100%'} direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={1} pr={2}>
                <Typography fontSize={13}>{arrayStatus.find(status => status.id == value)?.value}</Typography>
                <PipeTallyColorStatus code={value} />
            </Stack>
        ),
    },
    {
        field: 'type',
        headerName: 'Type',
        width: 100,
        renderCell: ({ value }) => (
            <Typography fontSize={13}>{arrayType.find(type => type.id == value).value}</Typography>
        ),
    },
    {
        field: 'footage',
        headerName: 'Footage',
        width: 100,
    },
    {
        field: 'quantity',
        headerName: 'Joints',
        width: 100,
    },
    {
        field: 'purchaseOrder',
        headerName: 'Order',
        width: 110,
    },
    {
        field: 'customer',
        headerName: 'End User',
        width: 130,
    },
    {
        field: 'connectionName',
        headerName: 'Pipe Name',
        flex: 1,
    },
    {
        field: 'location', headerName: 'Location', flex: 1,
        valueGetter: (params) => params.row.location.siteName,
        renderCell: (params) => (
            <Typography
                color="#262B40"
                variant="primary"
                fontFamily={"Roboto"}
                fontSize={'13px'}
                fontWeight='normal'
            >
                {params.value}
            </Typography>
        ),
    },
];

export const rows = [
    {
        id: 1,
        tallyNo: "1030",
        title: 'Test',
        status: '0',
        type: '0',
        footage: '1,279',
        quantity: '4',
        purchaseOrder: '1T2',
        customer: 'New user',
        pipeProfileName: '8.625 API Casing',
        location: "Storage Houston",
    }
]