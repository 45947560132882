
export const columns = [
    {
        field: 'od',
        headerName: 'O.D.',
        width: 85,
    },
    {
        field: 'length',
        headerName: 'Length',
        width: 85,
    },
    {
        field: 'wt',
        headerName: 'WT (#/ft)',
        width: 85,
    },
    {
        field: 'wall',
        headerName: 'Wall',
        width: 85,
    },
    {
        field: 'grade',
        headerName: 'Grade',
        width: 85,
    },
    {
        field: 'range',
        headerName: 'Range',
        width: 85,
    },
    {
        field: 'conn',
        headerName: 'Conn.',
        width: 85,
    },
    {
        field: 'iD',
        headerName: 'I.D.',
        width: 85,
    },
    {
        field: 'pinTong',
        headerName: 'Pin Tong',
        width: 85,
    },
    {
        field: 'boxTong',
        headerName: 'Box Tong',
        width: 85,
    },
    {
        field: 'hb',
        headerName: 'HB',
        flex: 1,
    },
    {
        field: 'ipc',
        headerName: 'IPC',
        flex: 1,
    },
    {
        field: 'serial',
        headerName: 'Serial',
        flex: 1,
    },
    {
        field: 'service',
        headerName: 'Service Co.',
        flex: 1,
    },
    {
        field: 'date',
        headerName: 'Date',
        flex: 1,
    },
    {
        field: 'workOrder',
        headerName: 'Work Order',
        flex: 1,
    },
];

export const rows = [
    {
        id: 1,
        od: '00.000',
        length: '00.000',
        wt: '00.000',
        wall: '00.000',
        grade: 'J55',
        range: 'R3',
        conn: 'API EUE',
        iD: '00.000',
        pinTong: '00.000',
        boxTong: '00.000',
        hb: 'DURABAND',
        ipc: 'IPC',
        serial: '1002445',
        service: 'IOS Tubular',
        date: '01/02/2023',
        workOrder: '230014',
    },
    {
        id: 2,
        od: '00.000',
        length: '00.000',
        wt: '00.000',
        wall: '00.000',
        grade: 'J55',
        range: 'R3',
        conn: 'API EUE',
        iD: '00.000',
        pinTong: '00.000',
        boxTong: '00.000',
        hb: 'DURABAND',
        ipc: 'IPC',
        serial: '1002445',
        service: 'IOS Tubular',
        date: '01/02/2023',
        workOrder: '230014',
    },
    {
        id: 3,
        od: '00.000',
        length: '00.000',
        wt: '00.000',
        wall: '00.000',
        grade: 'J55',
        range: 'R3',
        conn: 'API EUE',
        iD: '00.000',
        pinTong: '00.000',
        boxTong: '00.000',
        hb: 'DURABAND',
        ipc: 'IPC',
        serial: '1002445',
        service: 'IOS Tubular',
        date: '01/02/2023',
        workOrder: '230014',
    },
    {
        id: 4,
        od: '00.000',
        length: '00.000',
        wt: '00.000',
        wall: '00.000',
        grade: 'J55',
        range: 'R3',
        conn: 'API EUE',
        iD: '00.000',
        pinTong: '00.000',
        boxTong: '00.000',
        hb: 'DURABAND',
        ipc: 'IPC',
        serial: '1002445',
        service: 'IOS Tubular',
        date: '01/02/2023',
        workOrder: '230014',
    },
    {
        id: 5,
        od: '00.000',
        length: '00.000',
        wt: '00.000',
        wall: '00.000',
        grade: 'J55',
        range: 'R3',
        conn: 'API EUE',
        iD: '00.000',
        pinTong: '00.000',
        boxTong: '00.000',
        hb: 'DURABAND',
        ipc: 'IPC',
        serial: '1002445',
        service: 'IOS Tubular',
        date: '01/02/2023',
        workOrder: '230014',
    },
];

export const wallThicknessData = [
    {
        id: 1,
        wall: '0.217',
        updatedAt: '11/02/23 1:23PM',
        percentage: 90,
        health: 'AAA',
        color: '#36B368'
    },
    {
        id: 2,
        wall: '0.214',
        updatedAt: '11/02/23 1:23PM',
        percentage: 40,
        health: 'AA',
        color: '#0FB5FF'
    },
    {
        id: 3,
        wall: '0.274',
        updatedAt: '11/02/23 1:23PM',
        percentage: 30,
        health: 'AA',
        color: '#0FB5FF'
    },
    {
        id: 4,
        wall: '0.205',
        updatedAt: '11/02/23 1:23PM',
        percentage: 30,
        health: 'A',
        color: '#FFAA50'
    },

    {
        id: 5,
        wall: '0.254',
        updatedAt: '11/02/23 1:23PM',
        percentage: 30,
        health: 'EOL',
        color: '#E83880'
    },
    {
        id: 6,
        wall: '0.254',
        updatedAt: '11/02/23 1:23PM',
        percentage: 30,
        health: 'EOL',
        color: '#E83880'
    },
]
