import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography, MenuItem, Backdrop, CircularProgress } from '@mui/material';
import { Add } from '@mui/icons-material';
import { DefaultIconButton } from '../../components/DefaultIconButton/index.js';
import { DefaultDataGrid } from '../../components/DefaultDataGrid/index.js';
import { ScrollContent } from '../../components/ScrollContent/index.js';
import { SearchInput } from '../../components/SearchInput';
import { DefaultButton } from '../../components/DefaultButton';
import { Filters } from '../../components/Filters/index.js';
import { ColoredInfo } from '../../components/ColoredInfo/index.js';
import api from '../../lib/axios';
import { PageWrapper } from '../../components/PageWrapper/index.js';
import { arrayStatus, columns } from './Data/PipeTallyData.jsx';
import { PipeTallyNew } from '../PipeTallyDetail/PipeTallyNew.jsx';
import { useNavigate } from 'react-router-dom';
export const PipeTally = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [arrayOD, setArrayOD] = useState([]);
    const [arrayWallThickness, setArrayWallThickness] = useState([]);
    const [arrayGrade, setArrayGrade] = useState([]);
    const [arrayConnection, setArrayConnection] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(-1)
    const [selectedOD, setSelectedOD] = useState(-1)
    const [selectedWallThick, setSelectedWallThick] = useState(-1)
    const [selectedGrade, setSelectedGrade] = useState(-1)
    const [selectedConnection, setSelectedConnection] = useState(-1)
    const [query, setQuery] = useState("");
    const [filteredArrayData, setFilteredArrayData] = useState([]);
    const [arrayData, setArrayData] = useState([]);
    const [isActiveDetails, setIsActiveDetails] = useState(false)
    const [selectedPipeTallyID, setSelectedPipeTallyID] = useState(null)

    useEffect(() => {
        getFilterData()
    }, []);

    function getFilterData() {
        setIsLoading(true)
        api.get('/apis/Tallies/filterDetail').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayOD(resData.arrayOD)
                setArrayWallThickness(resData.arrayWallThick)
                setArrayGrade(resData.arrayGrade)
                setArrayConnection(resData.arrayConnection)

            }
            getData(selectedOD, selectedWallThick, selectedGrade, selectedConnection, selectedStatus)
            //setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    function getData(selectedOD, selectedWallThick, selectedGrade, selectedConnection, selectedStatus) {
        //setIsLoading(true)
        var data = {
            od: selectedOD != -1 ? selectedOD : "",
            wallThickness: selectedWallThick != -1 ? selectedWallThick : "",
            grade: selectedGrade != -1 ? selectedGrade : "",
            connection: selectedConnection != -1 ? selectedConnection : "",
            status: selectedStatus != -1 ? selectedStatus : ""
        }
        api.post('/apis/Tallies/getAll', data).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var filterArray = applyFilters(resData, query, selectedStatus)
                setFilteredArrayData(filterArray)
                setArrayData(resData)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    function applyFilters(arrayDataAll, query, selectedStatus) {
        var finalArray = []
        console.log("data", query)
        console.log("selectedStatus", selectedStatus)
        var filteredArraySearch = arrayDataAll.filter((dataObj) => {
            let matches = true;
            var properties = []
            properties = ['tallyNo', 'title', 'footage', 'quantity', 'purchaseOrder', 'userName', 'location.siteName', 'pipeProfileName'];
            let containsQuery = false;

            properties.forEach((property) => {
                var str = getProperty(dataObj, property) + "";
                if (str.toLowerCase().includes(query.toLowerCase())) {
                    containsQuery = true;
                }
            });
            if (!containsQuery) {
                matches = false;
            }
            return matches;
        });
        finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : [];
        if (selectedStatus !== null && selectedStatus !== -1) {
            var filteredArrayStats = finalArray
                .filter((dataObj) => {
                    let matches = true;
                    let containsQuery = false;
                    if (dataObj.status == selectedStatus) {
                        containsQuery = true;
                    }
                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                })
            finalArray = [...filteredArrayStats]
        }
        if (query === "" && selectedStatus === -1) {
            return arrayDataAll;
        }
        return finalArray;
    }

    function getProperty(obj, prop) {
        const props = prop.split('.');
        return props.reduce((o, p) => o && o[p], obj);
    }

    function handleQueryChange(event) {
        setQuery(event.target.value)
        var filterArray = applyFilters(arrayData, event.target.value, selectedStatus)
        setFilteredArrayData(filterArray)
    }

    function handleFilterChange(selectedOD, selectedWallThick, selectedGrade, selectedConnection, selectedStatus) {
        setIsLoading(true)
        getData(selectedOD, selectedWallThick, selectedGrade, selectedConnection, selectedStatus)
    }
    return (
        <>
            <PageWrapper>
                <Stack flexGrow={1} spacing={4}>
                    <Stack
                        direction={'row'}
                        spacing={3}
                        justifyContent={'space-between'}
                        p={3}
                        sx={{
                            backgroundColor: '#F5F7FB',
                        }}
                    >
                        <Stack direction={'row'} spacing={6}>
                            <Stack alignItems={'flex-start'}>
                                <Typography fontWeight={700} color="#262B40" fontSize={23}>Strings</Typography>
                            </Stack>
                            <ColoredInfo
                                color={'#FF3841'}
                                title={'Total Strings'}
                                subTitle={'Last 24 hrs'}
                                //value={this.state.arrayWorders.length}
                                value={1}
                            />
                            <ColoredInfo
                                color={'#36B368'}
                                title={'In Service'}
                                subTitle={'Last 24 hrs'}
                                value={1}
                            />
                            <ColoredInfo
                                color={'#0875E1'}
                                title={'Utilization'}
                                subTitle={'Assets'}
                                value={"100%"}
                            />
                            <Stack spacing={2} alignItems={'flex-start'}>
                                <Box>
                                    <Typography >Compared</Typography>
                                    <Typography fontSize={13} color="#8F98AB">
                                        To last 30 days
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography fontSize={20} fontWeight={700} color="#FF3841">
                                        +0%
                                    </Typography>
                                </Box>
                            </Stack>
                        </Stack>
                        <Box borderLeft="1px solid #D5D5D5" height='70px' />

                        <Stack direction={'row'} spacing={6}>
                            <Stack alignItems={'flex-start'}>
                                <Typography fontWeight={700} color="#262B40" fontSize={15}>String Health</Typography>
                                <Typography color="#8F98AB" fontSize={13}>Overview</Typography>
                            </Stack>
                            <ColoredInfo
                                color={'#36B368'}
                                title={'AAA'}
                                subTitle={'Over 100%'}
                                value={100}
                            />
                            <ColoredInfo
                                color={'#0FB5FF'}
                                title={'AA'}
                                subTitle={'99.9% - 95%'}
                                value={0}
                            />
                            <ColoredInfo
                                color={'#FFAA50'}
                                title={'A'}
                                subTitle={'94.9% - 90%'}
                                value={0}
                            />
                            <ColoredInfo
                                color={'#E83880'}
                                title={'EOL'}
                                subTitle={'89.9% - 80%'}
                                value={0}
                            />
                            <ColoredInfo
                                color={'#262B40'}
                                title={'DBR/Downgrade'}
                                subTitle={'DBR – 79.99% Lower'}
                                value={0}
                            />
                        </Stack>
                    </Stack>
                    <Stack px={3} spacing={3}>
                        <Stack direction={'row'} alignItems={'center'} spacing={5}>
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={"100%"} spacing={3}>
                                <SearchInput handleQueryChange={handleQueryChange} />
                                <Filters
                                    handleFilerChange={(event) => {
                                        var od = event.target.value
                                        setSelectedOD(od)
                                        handleFilterChange(od, selectedWallThick, selectedGrade, selectedConnection, selectedStatus)
                                    }}
                                    value={selectedOD}
                                    name="selectedOD"
                                    placeholder="O.D."
                                    width="10vw"
                                >
                                    {arrayOD.map((item, index) => (
                                        <MenuItem key={index} value={item}> {item}</MenuItem>
                                    ))}
                                </Filters>

                                <Filters
                                    handleFilerChange={(event) => {
                                        var wallThick = event.target.value
                                        setSelectedWallThick(wallThick)
                                        handleFilterChange(selectedOD, wallThick, selectedGrade, selectedConnection, selectedStatus)
                                    }}
                                    value={selectedWallThick}
                                    name="selectedWallThick"
                                    placeholder="Wall. Thick."
                                    width="10vw"
                                >

                                    {arrayWallThickness.map((item, index) => (
                                        <MenuItem key={index} value={item}> {item}</MenuItem>
                                    ))}
                                </Filters>

                                <Filters
                                    handleFilerChange={(event) => {
                                        var grade = event.target.value
                                        setSelectedGrade(grade)
                                        handleFilterChange(selectedOD, selectedWallThick, grade, selectedConnection, selectedStatus)
                                    }}
                                    value={selectedGrade}
                                    name="selectedGrade"
                                    placeholder="Grade"
                                    width="10vw"
                                >
                                    {arrayGrade.map((item, index) => (
                                        <MenuItem key={index} value={item}> {item}</MenuItem>
                                    ))}
                                </Filters>

                                <Filters
                                    handleFilerChange={(event) => {
                                        var connection = event.target.value
                                        setSelectedConnection(connection)
                                        handleFilterChange(selectedOD, selectedWallThick, selectedGrade, connection, selectedStatus)
                                    }}
                                    value={selectedConnection}
                                    name="selectedConnection"
                                    placeholder="Connection"
                                    width="10vw"
                                >
                                    {arrayConnection.map((item, index) => (
                                        <MenuItem key={index} value={item}> {item}</MenuItem>
                                    ))}
                                </Filters>

                                <Filters
                                    handleFilerChange={(event) => {
                                        var status = event.target.value
                                        setSelectedStatus(status)
                                        handleFilterChange(selectedOD, selectedWallThick, selectedGrade, selectedConnection, status)
                                    }}
                                    value={selectedStatus}
                                    name="selectedStatus"
                                    placeholder="Status"
                                    width="10vw"
                                >
                                    {arrayStatus.map((item, index) => (
                                        <MenuItem key={index} value={item.id}> {item.value}</MenuItem>
                                    ))}
                                </Filters>
                            </Stack>
                            <Box flexGrow={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <DefaultButton
                                    sx={{
                                        p: '5px 12px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '14px',
                                        fontWeight: 700,
                                    }}
                                    onClick={(e) => {
                                        setSelectedPipeTallyID(null)
                                        setIsActiveDetails(true)
                                    }}
                                >

                                    <DefaultIconButton
                                        sx={{
                                            pointerEvents: 'none',
                                            backgroundColor: 'secondary.main',
                                            color: 'common.white',
                                        }}
                                        icon={<Add />}
                                    />
                                    Add Pipe Tally
                                </DefaultButton>
                            </Box>
                        </Stack>
                    </Stack>
                    <Box flexGrow={1}>
                        <ScrollContent>
                            <Box
                                px={3}
                                pb={3}
                                height={600}
                                sx={{
                                    overflowX: 'auto',
                                }}
                            >
                                <DefaultDataGrid
                                    columns={columns}
                                    rows={filteredArrayData}
                                    onRowClick={(e) => {
                                        console.log(e)
                                        // setSelectedPipeTallyID(e.id)
                                        // setIsActiveDetails(true)
                                        navigate('/pipe-tally/manage',
                                            {
                                                state: { id: e.id }
                                            })
                                    }}
                                />
                            </Box>
                        </ScrollContent>
                        {isActiveDetails && (
                            <PipeTallyNew
                                selectedDataId={selectedPipeTallyID}
                                closeDetails={(e) => {
                                    setIsActiveDetails(false)
                                    getData(selectedOD, selectedWallThick, selectedGrade, selectedConnection, selectedStatus)
                                }}
                            />
                        )}
                    </Box>
                </Stack>
                <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </PageWrapper>
        </>
    )
}