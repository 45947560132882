import { Box, MenuItem, Stack, Typography } from '@mui/material';
import { Filters } from '../../components/Filters/index.js';
import { SearchInput } from '../../components/SearchInput/index.js';
import React, { useEffect, useState } from 'react';
import { DefaultButton } from '../../components/DefaultButton/index.js';
import { ScrollContent } from '../../components/ScrollContent/index.js';
import { DefaultDataGrid } from '../../components/DefaultDataGrid/index.js';
import { ArrowForwardIos } from '@mui/icons-material';
import { arrayStatus, getHealth, getHealthColor } from './Data/tableData.jsx';
import { EditNote } from '@mui/icons-material';
import { PipeTallyNew } from '../PipeTallyDetail/PipeTallyNew.jsx';
import QrCode from '../../assets/images/qr-code.svg'
import moment from 'moment';
import Moment from 'react-moment';
import { ColorStatus } from 'components/ColorStatus/ColorStatus.jsx';
import { PipeTallyDocumentAlert } from './PipeTallyDocumentAlert.jsx';
import { enqueueSnackbar } from 'notistack';
import styles from './index.module.scss';

export const Overview = ({ objPipeTally, selectedDataId, getData }) => {
    const [query, setQuery] = useState("");
    const [selectedStatus, setSelectedStatus] = useState(-1);
    const [filteredArrayData, setFilteredArrayData] = useState([]);
    const [selectedPipe, setSelectedPipe] = useState(null);
    const [arrayPipe, setArrayPipe] = useState([]);
    const [objPipeTallys, setObjPipeTallys] = useState({})
    const [objPipeProfile, setObjPipeProfile] = useState({})
    const [objLocation, setObjLocation] = useState({})
    const [isDocumentDialogueShouldOpen, setIsDocumentDialogueShouldOpen] = useState(false);
    const [isActiveDetails, setIsActiveDetails] = useState(false)
    const [arrayAttribute, setArrayAttribute] = useState([]);
    const columns = [
        {
            field: 'heat', headerName: 'Heat', width: 110,
            renderCell: (params) => (
                <Typography>
                    {params.value ? params.value : '-'}
                </Typography>
            )
        },
        {
            field: 'lot', headerName: 'Lot', width: 110,
            renderCell: (params) => (
                <Typography>
                    {params.value ? params.value : '-'}
                </Typography>
            )
        },
        {
            field: 'jnt', headerName: 'Joint', width: 110,
            renderCell: ({ value }) => (
                <Typography
                    color="#262B40"
                    variant="primary"
                    fontFamily={"Roboto"}
                    fontSize={'13px'}
                    fontWeight='normal'
                >
                    {value != null && value.trim() != "" ? value : "-"}
                </Typography>
            )
        },
        {
            field: 'length', headerName: 'Length', width: 110,
            renderCell: ({ value }) => (
                <Typography
                    color="#262B40"
                    variant="primary"
                    fontFamily={"Roboto"}
                    fontSize={'13px'}
                    fontWeight='normal'
                >
                    {value != null && value.trim() !== "" ? value : "-"}
                </Typography>
            )
        },
        {
            field: 'serialId1', headerName: 'Serial ID1', flex: 1,
            renderCell: (params) => (
                <Typography>
                    {params.value ? params.value : '-'}
                </Typography>
            )
        },
        {
            field: 'serialId2', headerName: 'Serial ID2', flex: 1,
            renderCell: (params) => (
                <Typography>
                    {params.value ? params.value : '-'}
                </Typography>
            )
        },
        {
            field: 'serialId3', headerName: 'Serial ID3', flex: 1,
            renderCell: (params) => (
                <Typography>
                    {params.value ? params.value : '-'}
                </Typography>
            )
        },
        {
            field: 'updatedAt', headerName: 'Updated', width: 160, renderCell: ({ value }) => (
                <Stack direction={'row'} alignItems={'center'} spacing={2}>

                    <Typography fontSize={13}>
                        <Moment format='MM-DD-YYYY hh:mma'>
                            {value}
                        </Moment>
                    </Typography>
                </Stack>
            ),
        }, {
            field: 'status',
            headerName: 'Status',
            width: 130,
            renderCell: ({ value }) => (
                <Stack
                    width={'100%'}
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    spacing={1}
                    pr={2}
                >
                    <Typography fontSize={13}>{value ? arrayStatus.find((data) => data.id == value)?.value : "-"}</Typography>
                    <ColorStatus
                        code={value} />
                </Stack>
            ),
        },
        {
            field: 'mtrReports', headerName: 'Detail', width: 160,
            renderCell: (params) => (
                <Stack
                    width={'100%'}
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    spacing={1}
                    pr={2}
                    maxWidth={'150px'}
                    onClick={(e) => {
                        console.log("ON CLICK", params.row.pipeID)
                        if (params.row.pipeID) {
                            e.stopPropagation();
                            setSelectedPipe(params.row)
                            setIsDocumentDialogueShouldOpen(true)
                        } else {
                            enqueueSnackbar('Detail not found', {
                                anchorOrigin: {
                                    horizontal: 'right',
                                    vertical: 'top'
                                },
                                variant: 'error'
                            });
                        }
                    }}>
                    <Typography fontSize={13}>More Details</Typography>
                    <ArrowForwardIos
                        sx={{
                            width: 13,
                            height: 13,
                        }}
                    />
                </Stack>
            ),
        },
    ];

    useEffect(async () => {
        if (objPipeTally != null) {

            setData(objPipeTally)
        }
    }, [objPipeTally]);

    function handleQueryChange(event) {
        setQuery(event.target.value)
        var filterArray = applyFilters(arrayPipe, event.target.value, selectedStatus)
        setFilteredArrayData(filterArray)
    }

    function applyFilters(arrayDataAll, query, selectedStatus) {
        var finalArray = []
        var filteredArraySearch = arrayDataAll.filter((dataObj) => {
            let matches = true;
            const properties = ['joint', 'length', 'serialId', 'health', 'fullName'];
            let containsQuery = false;
            properties.forEach((property) => {
                var str = getProperty(dataObj, property) + "";
                if (str.toLowerCase().includes(query.toLowerCase())) {
                    containsQuery = true;
                }
            });
            if (dataObj.arrayIdentifier && dataObj.arrayIdentifier.length > 0) {
                var str = dataObj.arrayIdentifier[0] + "";
                if (str.toLowerCase().includes(query.toLowerCase())) {
                    containsQuery = true;
                }
            }
            if (!containsQuery) {
                matches = false;
            }
            return matches;
        });
        finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : [];
        if (selectedStatus !== null && selectedStatus !== -1) {
            var filteredArrayStats = finalArray
                .filter((dataObj) => {
                    let matches = true;
                    let containsQuery = false;
                    if (dataObj.status == selectedStatus) {
                        containsQuery = true;
                    }
                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                })
            finalArray = [...filteredArrayStats]
        }
        if (query === "" && selectedStatus === -1) {
            return arrayDataAll;
        }
        finalArray = finalArray.map((item, index) => ({ ...item, joint: index + 1 }));
        return finalArray;
    }


    function setData(resData) {
        console.log("resData", resData.pipeProfile.attributes)
        setObjPipeTallys(resData)
        setArrayAttribute(resData.pipeProfile.attributes)
        setObjPipeProfile(resData.pipeProfile)
        setObjLocation(resData.location)
        var pipeData = resData.pipes
        var indexCounter = 0;
        pipeData = pipeData.map(item => {
            indexCounter++;
            return { ...item, joint: indexCounter };
        });
        var filterArray = applyFilters(pipeData, "", selectedStatus)
        setFilteredArrayData(filterArray)
        setArrayPipe(pipeData)
    }

    function getProperty(obj, prop) {
        const props = prop.split('.');
        return props.reduce((o, p) => o && o[p], obj);
    }

    return (
        <>
            <Stack width={'100%'} mt={1}>
                <Stack px={3} spacing={3}>
                    <Stack direction={'row'} alignItems={'center'} spacing={3}>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={"50%"} spacing={3}>
                            <SearchInput handleQueryChange={handleQueryChange} />
                            <Filters
                                handleFilerChange={(event) => {
                                    setSelectedStatus(event.target.value)
                                    var filterArray = applyFilters(arrayPipe, query, event.target.value)
                                    setFilteredArrayData(filterArray)
                                }}
                                value={selectedStatus}
                                name="selectedStatus"
                                placeholder="Status"
                            >
                                {arrayStatus.map((item, index) => (
                                    <MenuItem key={index} value={item.id}> {item.value}</MenuItem>
                                ))}
                            </Filters>

                        </Stack>
                        <Box flexGrow={1} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <DefaultButton
                                startIcon={<EditNote />}
                                sx={{
                                    border: '1px solid black',
                                    borderColor: 'grey.light',
                                    p: '13px 40px 13px 20px',
                                    fontWeight: 700,
                                    '.MuiButton-startIcon': {
                                        mr: 2,
                                        pr: 2,
                                        borderRight: '1px solid black',
                                        borderColor: 'grey.light',
                                        transition:
                                            'padding .15s, border-color .15s',
                                    },

                                    '&:hover': {
                                        backgroundColor: 'grey.light',
                                        '.MuiButton-startIcon': {
                                            pr: 0,
                                        },
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                                onClick={() => {
                                    setIsActiveDetails(true)
                                }}
                            >
                                Edit pipe tally
                            </DefaultButton>
                        </Box>
                    </Stack>
                </Stack>
                <Box flexGrow={1} mt={3}>
                    <ScrollContent>
                        <Box px={3}>
                            <DefaultDataGrid
                                columns={columns}
                                rows={filteredArrayData}
                            />
                        </Box>
                    </ScrollContent>
                </Box>
                {isActiveDetails && (
                    <PipeTallyNew
                        selectedDataId={selectedDataId}
                        closeDetails={(e) => {
                            setIsActiveDetails(false)
                            getData()
                        }}
                    />
                )}
            </Stack >

            {isDocumentDialogueShouldOpen && (
                <PipeTallyDocumentAlert
                    //connection={objPipeTally.connection && objPipeTally.connection != "" ? objPipeTally.connection + " " + objPipeTally.connectionOd + " OD x " + objPipeTally.connectionId + " ID" : "-"}
                    connection={objPipeTally.pipeProfile.connectionName}
                    objPipe={selectedPipe}
                    updateConfirmAlert={(e) => {
                        setIsDocumentDialogueShouldOpen(false)
                        console.log(e)
                    }}
                />
            )}
        </>
    );
};
