import { Backdrop, Box, CircularProgress, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ScrollContent } from '../../components/ScrollContent/index.js';
import { DefaultDataGrid } from '../../components/DefaultDataGrid/index.js';
import api from '../../lib/axios'
import { Formik } from 'formik';
import * as Yup from 'yup';
import { enqueueSnackbar } from 'notistack';
import { DefaultIconButton } from '../../components/DefaultIconButton/index.js';
import { ConfirmAlert } from 'customControls/confirmAlert.js';
import { Close } from '@mui/icons-material';
import { CircleButtonGreen } from '../../components/CircleButtonGreen/CircleButtonGreen.jsx';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import styles from './index.module.scss';
import { DefaultButton } from 'components/DefaultButton/DefaultButton.jsx';
import { Title } from 'components/Title/index.js';


export const PipeTallyNotes = ({ pipeTallyID }) => {
    const [selectedDataId, setSelectedDataId] = useState(pipeTallyID);
    const [isLoading, setIsLoading] = useState(false);
    const [arrayNotesData, setArrayNotesData] = useState([]);
    const [text, setText] = useState({});
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState("");
    const [notesID, setNotesID] = useState("");
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isForEdit, setIsForEdit] = useState(false);
    const columns = [
        {
            field: 'text',
            headerName: 'Notes',
            flex: 1,
        },
        {
            field: 'fullName',
            headerName: 'User',
            width: 200,
        },
        {
            field: 'updatedAt',
            headerName: 'Last Updated',
            width: 250,
            valueFormatter: (params) => {
                const date = new Date(params.value);
                const day = date.getDate().toString().padStart(2, '0');
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const year = date.getFullYear();
                let hours = date.getHours();
                const ampm = hours >= 12 ? 'PM' : 'AM';
                hours = hours % 12 || 12;
                const minutes = date.getMinutes().toString().padStart(2, '0');
                const formattedDate = `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
                return formattedDate;
            },
        },
        {
            field: 'editIcon',
            headerName: '',
            width: 100,
            renderCell: (params) => (
                <Stack alignItems={'flex-end'} width={'100%'} pr={1}>
                    <DefaultIconButton
                        sx={{
                            backgroundColor: 'primary.main',
                            color: 'common.white',
                            '&:hover': {
                                backgroundColor: 'red.main',
                            },
                        }}
                        icon={<ModeOutlinedIcon />}
                        onClick={(e) => {
                            setIsPopupOpen(true)
                            setText(params.row.text)
                            setNotesID(params.row._id)
                            setIsForEdit(true)
                            e.stopPropagation();
                        }}
                    />
                </Stack>
            ),
            sortable: false,
            disableColumnMenu: true,
        },
        {
            field: 'deleteIcon',
            headerName: '',
            width: 100,
            renderCell: (params) => (
                <Stack alignItems={'flex-end'} width={'100%'} pr={5}>
                    <DefaultIconButton
                        sx={{
                            backgroundColor: 'primary.main',
                            color: 'common.white',
                            '&:hover': {
                                backgroundColor: 'red.main',
                            },
                        }}
                        icon={<Close />}
                        onClick={(e) => {
                            e.stopPropagation();
                            setDeleteMessage("Are you sure you want to delete?")
                            setDeleteConfirmationShouldOpen(true)
                            setNotesID(params.row._id)
                        }}
                    />
                </Stack>
            ),
            sortable: false,
            disableColumnMenu: true,
        },
    ]

    useEffect(() => {
        console.log("ID", pipeTallyID)
        getNotesData()
    }, []);

    function getNotesData() {
        setIsLoading(true)
        api.get('/apis/TallyNotes/byPipeTally/' + selectedDataId).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayNotesData(resData)
                setText(resData.text)
                setIsLoading(false)
            }

        }).catch(function (error) {
            console.log(error);
        });
    }
    async function apiCalling(notes, tallyID, notesID) {
        var data = {
            text: notes,
            tallyID: tallyID,
        }
        var notesData = {
            text: notes,
        }
        console.log("IF", selectedDataId)
        if (!isForEdit) {
            console.log("IF")
            return await api.post('/apis/TallyNotes/add', data)
        } else {
            return await api.put('/apis/TallyNotes/updateById/' + notesID, notesData)
        }
    }
    function deleteNotes() {
        setIsLoading(true)
        api.delete('/apis/TallyNotes/deleteById/' + notesID).then((res) => {
            if (res.data.success) {
                enqueueSnackbar("Notes deleted", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                getNotesData()
                setIsPopupOpen(false)
            } else {
                setDeleteConfirmationShouldOpen(false)
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    const updateConfirmAlert = (value) => {
        if (value) {
            deleteNotes()
        } else {
            setDeleteConfirmationShouldOpen(false)
        }
    }
    return (
        <>
            <Stack width={'100%'} mt={1}>
                <Box flexGrow={1} mt={3}>
                    <ScrollContent>
                        <Box px={3}>
                            <DefaultDataGrid
                                columns={columns}
                                rows={arrayNotesData}
                            />
                        </Box>
                    </ScrollContent>

                </Box>
                <Stack
                    alignItems={"end"}
                    mb={2}
                    mt={2}
                    mr={1}>
                    <CircleButtonGreen
                        onClick={() => {
                            setIsPopupOpen(true);
                            setIsForEdit(false)
                            setNotesID("")
                            setText("")
                        }}
                    />
                </Stack >
                {isPopupOpen === true &&
                    <Box
                        sx={{
                            borderLeft: '1px solid black',
                            borderColor: 'grey.light',
                            backgroundColor: 'common.white',
                        }}
                        className={styles.settingsDetails}
                    >
                        <ScrollContent>

                            <Formik
                                enableReinitialize
                                initialValues={{
                                    submit: null,
                                    text: text,
                                }}
                                validationSchema={Yup
                                    .object()
                                    .shape({
                                        text: Yup.string().required('text is required'),
                                    })}
                                onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                                    console.log("onsubmit")
                                    try {
                                        setIsLoading(true)
                                        var data = await apiCalling(values.text, selectedDataId, notesID)
                                        setIsLoading(false)
                                        if (data !== undefined && data.data.success) {
                                            console.log("IF")
                                            resetForm();
                                            setStatus({ success: true });
                                            setSubmitting(false);

                                            enqueueSnackbar('Notes ' + (notesID == "" ? "added" : "updated"), {
                                                anchorOrigin: {
                                                    horizontal: 'right',
                                                    vertical: 'top'
                                                },
                                                variant: 'success'
                                            });
                                            setIsPopupOpen(false)
                                            getNotesData()
                                        } else {
                                            console.log("ELSE")
                                            setStatus({ success: false });
                                            setErrors({ submit: data.data.message });
                                            setSubmitting(false);
                                            enqueueSnackbar(data.data.message, {
                                                anchorOrigin: {
                                                    horizontal: 'right',
                                                    vertical: 'top'
                                                },
                                                variant: 'error'
                                            });
                                        }
                                    } catch (err) {
                                        console.error("error12", err);
                                        setIsLoading(false)
                                        setStatus({ success: false });
                                        setErrors({ submit: err.message });
                                        setSubmitting(false);
                                    }

                                }}
                            >
                                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                                    <form
                                        onSubmit={handleSubmit}
                                    >
                                        <Stack p={'35px 50px'} spacing={4}>
                                            <Title>Notes</Title>
                                            <TextField
                                                multiline={true}
                                                sx={{
                                                    flexGrow: 1,
                                                    border: '1px solid',
                                                    borderColor: 'grey.light',
                                                    '.MuiInputBase-root': {
                                                        height: '300px',
                                                    },
                                                    '.MuiInputBase-input': {
                                                        height: '100% !important',
                                                        overflowY: 'auto !important',
                                                        '&::-webkit-scrollbar': {
                                                            width: '4px',
                                                        },
                                                        '&::-webkit-scrollbar-thumb': {
                                                            width: '4px',
                                                            bgcolor: 'grey.400',
                                                        },
                                                    },
                                                }}
                                                value={values.text}
                                                name={"text"}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                            <Stack
                                                p={'35px 50px'}
                                                direction={'row'}
                                                justifyContent={'space-between'}
                                                mt={'auto'}
                                            >
                                                <Box/>
                                                <Stack direction={'row'} spacing={2}>
                                                    <DefaultButton
                                                        sx={{ color: 'secondary.main' }}
                                                        onClick={(e) => {
                                                            setIsPopupOpen(false)
                                                        }}
                                                    >
                                                        Cancel
                                                    </DefaultButton>
                                                    <DefaultButton
                                                        variant={'contained'}
                                                        disabled={isSubmitting}
                                                        type="submit"
                                                    >
                                                        {notesID == "" ? "Add" : "Update"}
                                                    </DefaultButton>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </form>
                                )}
                            </Formik>
                        </ScrollContent>
                    </Box>
                }
                {
                    deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={deleteMessage}
                        updateConfirmAlert={(e, value) => {
                            console.log(e, value)
                            setDeleteConfirmationShouldOpen(false)
                            updateConfirmAlert(value)
                        }} />)
                }
                <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Stack >
        </>
    )
}