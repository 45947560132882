import React from 'react';
import { Box } from '@mui/material';
import { getPipeTallyStatusColor, getStatusColor } from '../../core/functions/statusHelpers.js';

export const ColorStatus = ({ code, size = 10 }) => {
    return (
        <Box
            sx={{
                width: size,
                height: size,
                borderRadius: '50%',
                backgroundColor: getStatusColor(code),
            }}
        />
    );
};

export const PipeTallyColorStatus = ({ code, size = 10 }) => {
    return (
        <Box
            sx={{
                width: size,
                height: size,
                borderRadius: '50%',
                backgroundColor: getPipeTallyStatusColor(code),
            }}
        />
    );
};

