import { Backdrop, Box, CircularProgress, Stack } from '@mui/material';
import React, { useEffect, useState, createRef } from 'react';
import { ScrollContent } from '../../components/ScrollContent/index.js';
import { DefaultDataGrid } from '../../components/DefaultDataGrid/index.js';
import moment from 'moment';
import api from 'lib/axios';
import { enqueueSnackbar } from 'notistack';
import { DefaultIconButton } from '../../components/DefaultIconButton/index.js';
import { ConfirmAlert } from 'customControls/confirmAlert.js';
import { Close } from '@mui/icons-material';
import { CircleButtonGreen } from '../../components/CircleButtonGreen/CircleButtonGreen.jsx';

export const PipeTallyAttachment = ({ pipeTallyID, disabled }) => {
    const mainFileRef = createRef()
    const [selectedDataId, setSelectedDataId] = useState(pipeTallyID);
    const [isLoading, setIsLoading] = useState(false);
    const [arrayInventoryAttachment, setArrayInventoryAttachment] = useState([]);
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState("");
    const [attachmentID, setAttachmentID] = useState("");
    const columns = [
        {
            field: 'name',
            headerName: 'File Name',
            flex: 1,
        },
        {
            field: 'fullName',
            headerName: 'Uploaded By',
            width: 200,
        },
        {
            field: 'updatedAt',
            headerName: 'Last Updated',
            width: 250,
            valueFormatter: (params) => {
                return moment(params.value).format("MM-DD-YYYY hh:mm a")
            },
        },
        {
            field: 'deleteIcon',
            headerName: '',
            width: 100,
            renderCell: (params) => (
                <Stack alignItems={'flex-end'} width={'100%'} pr={5}>
                    <DefaultIconButton
                        sx={{
                            backgroundColor: 'primary.main',
                            color: 'common.white',
                            '&:hover': {
                                backgroundColor: 'red.main',
                            },
                        }}
                        icon={<Close />}
                        onClick={(e) => {
                            e.stopPropagation();
                            setDeleteMessage("Are you sure you want to delete?")
                            setDeleteConfirmationShouldOpen(true)
                            setAttachmentID(params.row._id)
                        }}
                    />
                </Stack>
            ),
            sortable: false,
            disableColumnMenu: true,
        },
    ];

    useEffect(() => {
        getAttachmentData()
    }, []);

    function deleteAttachment() {
        setIsLoading(true)
        api.delete('/apis/TallyAttachments/' + attachmentID).then((res) => {
            if (res.data.success) {
                enqueueSnackbar("File deleted", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                getAttachmentData()
            } else {
                setDeleteConfirmationShouldOpen(false)
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const updateConfirmAlert = (value) => {
        if (value) {
            deleteAttachment()
        } else {
            setDeleteConfirmationShouldOpen(false)
        }
    }
    function getAttachmentData() {
        setIsLoading(true)
        api.get('/apis/TallyAttachments/byPipeTally/' + selectedDataId).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayInventoryAttachment(resData)
                setIsLoading(false)
            }

        }).catch(function (error) {
            console.log(error);
        });
    }
    async function uploadFiles(file) {
        setIsLoading(true)
        const formData = new FormData();
        formData.append("file", file);
        api.post('/apis/TallyAttachments/uploadimage/' + selectedDataId, formData).then((res) => {
            if (res.data.success) {
                enqueueSnackbar("File Uploaded", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                setIsLoading(false)
                getAttachmentData()
            } else {
                setDeleteConfirmationShouldOpen(false)
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });

            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    return (
        <>
            <Stack width={'100%'} mt={1}>
                <Box flexGrow={1} mt={3}>
                    <ScrollContent>
                        <Box px={3}>
                            <DefaultDataGrid columns={columns} rows={arrayInventoryAttachment} />
                        </Box>
                    </ScrollContent>
                </Box>
                <Stack
                    alignItems={"end"}
                    mb={2}
                    mt={2}
                    mr={1}>
                    <CircleButtonGreen
                        onClick={(e) => {
                            if (mainFileRef.current) {
                                mainFileRef.current.value = null;
                                mainFileRef.current.click();
                            }
                            console.log("onClick");
                        }}
                    />
                    <input
                        type="file"
                        name="files"
                        hidden
                        ref={mainFileRef}
                        accept=".pdf"
                        onChange={(e) => {
                            const selectedFiles = e.target.files;
                            const isValidFileType = Array.from(selectedFiles).every((file) => {
                                return file.type === 'application/pdf';
                            });
                            if (!isValidFileType) {
                                enqueueSnackbar('Upload the file in the correct format (PDF)', {
                                    anchorOrigin: {
                                        horizontal: 'right',
                                        vertical: 'top',
                                    },
                                    variant: 'error',
                                });
                                return;
                            }
                            if (selectedFiles.length > 0) {
                                uploadFiles(selectedFiles[0]);
                            }
                        }}
                    />
                </Stack >
                {
                    deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={deleteMessage}
                        updateConfirmAlert={(e, value) => {
                            console.log(e, value)
                            setDeleteConfirmationShouldOpen(false)
                            updateConfirmAlert(value)
                        }} />)
                }
                <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Stack>
        </>
    )
}