import cn from 'classnames';
import React, { useRef, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { KeyboardArrowDown } from '@mui/icons-material';
import logo from '../../assets/images/logo.svg';
import styles from './index.module.scss';
import styleDropdown from '../Dropdown/index.module.scss';
import { Box, Stack, Typography, Menu, MenuItem } from '@mui/material';
import { Dropdown } from '../Dropdown/index.js';
import { DefaultAvatar } from '../DefaultAvatar/index.js';
import { Notifications, NotificationsOutlined } from '@mui/icons-material';
import useAuth from '../../hooks/useAuth';
// import { useSnackbar } from 'notistack';
export const Header = () => {
    const menuItems = useRef([
        {
            id: 1,
            title: 'Inventory',
            link: '/inventory',
        },
        {
            id: 2,
            title: 'Pipe Tally',
            link: '/pipe-tally',
        },
        {
            id: 4,
            title: 'Settings',
            link: '/settings',
        },
    ]);
    const { logout } = useAuth();
    const navigate = useNavigate();
    // const { enqueueSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);

    };

    const handleProfile = async () => {
        setAnchorEl(null);
        navigate('/profile/editProfile');
    };

    const handleChangePassword = async () => {
        setAnchorEl(null);
        navigate('/profile/changePassword');
    };

    const handleLogout = async () => {
        try {
            handleClose();
            await logout();
            navigate('authentication/login');
        } catch (err) {
            console.error(err);
            //   enqueueSnackbar('Unable to logout', {
            //     anchorOrigin: {
            //       horizontal: 'right',
            //       vertical: 'top'
            //     },
            //     variant: 'error'
            //   });
        }
    };

    const myString = localStorage.getItem('fullName') ? localStorage.getItem('fullName') : "";
    const words = myString.split(' ');
    const firstCharacters = words.map(word => word[0]);
    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            component={'header'}
            className={styles.header}
        >
            <Link className={styles.headerLogo} to={'/'}>
                <img src={logo} alt='logo' />
            </Link>
            <Box className={styles.headerCompany}>
                <Dropdown>
                    <Stack>
                        <Typography fontSize={15} fontWeight={700}>
                            {localStorage.getItem('companyName')}
                        </Typography>
                        <Typography color={'#8F98AB'} fontSize={13}>
                            Company
                        </Typography>
                    </Stack>
                </Dropdown>
            </Box>
            <Box component={'nav'} ml={6} className={styles.headerNav}>
                <ul className={styles.headerMenu}>
                    {menuItems.current.map((item) => (
                        <li className={styles.headerMenuItem} key={item.id}>
                            <NavLink
                                className={({ isActive }) =>
                                    cn(styles.headerMenuLink, {
                                        [styles.headerMenuLinkActive]: isActive || (item.link === '/inventory' && window.location.pathname === '/'),
                                    })
                                }
                                to={item.link}
                            >
                                {item.title}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </Box>

            <Stack direction={'row'} spacing={4} alignItems={'center'}>
                <Dropdown content={<Notifications />}>
                    <Stack direction={'row'} alignItems={'center'} spacing={2}>
                        <Box
                            sx={{
                                position: 'relative',
                                svg: {
                                    width: 28,
                                    height: 28,
                                    position: 'static',
                                    transform: 'unset',
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    width: 10,
                                    height: 10,
                                    backgroundColor: 'green.main',
                                    borderRadius: '10px',
                                    position: 'absolute',
                                    right: 3,
                                    top: 4,
                                    zIndex: 3,
                                }}
                            />
                            <NotificationsOutlined />
                        </Box>
                        <Typography fontSize={13}>Notifications</Typography>
                    </Stack>
                </Dropdown>
                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                    <DefaultAvatar>{firstCharacters.join('')}</DefaultAvatar>
                    {/* <Dropdown>
                        <Stack
                            alignItems={'center'}
                            justifyContent={'center'}
                            color={'#8F98AB'}
                            fontSize={13}
                            fontWeight={700}
                        >
                            Anton
                        </Stack>
                    </Dropdown> */}
                    <div>
                        <Box className={styleDropdown.button} onClick={handleClick}>
                            <Stack
                                alignItems={'center'}
                                justifyContent={'center'}
                                color={'#8F98AB'}
                                fontSize={13}
                                fontWeight={700}
                            >
                                {localStorage.getItem('fullName')}
                            </Stack>
                            <KeyboardArrowDown />
                        </Box>
                        <Menu
                            id='basic-menu'
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={handleProfile}>Profile</MenuItem>
                            <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                    </div>
                </Stack>
            </Stack>
        </Stack>
    );
};
