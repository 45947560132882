import { Box, Stack, Typography } from '@mui/material';

export const ColoredInfo = ({ title, subTitle, value, color, onClick }) => {
    return (
        <Stack spacing={2} alignItems={'flex-start'} onClick={onClick}>
            <Box>
                <Typography fontWeight={700} color="#262B40">{title}</Typography>
                <Typography fontSize={13} color="#8F98AB"   whiteSpace= 'nowrap'>
                    {subTitle}
                </Typography>
            </Box>
            <Box>
                <Typography fontSize={20} fontWeight={700} color="#262B40">
                    {value}
                </Typography>
                <Box
                    sx={{
                        backgroundColor: color || 'common.white',
                        height: 3,
                        width: '60px',
                    }}
                />
            </Box>
        </Stack>
    );
};
