import React, { useEffect } from 'react';
import { useRoutes,useLocation } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import SplashScreen from './components/SplashScreen';
import { gtmConfig } from './config';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import gtm from './lib/gtm';
import routes from './routes';
import { Header } from './components/Header/index.js';
import { Footer } from './components/Footer/index.js';
const App = () => {
  const location = useLocation();
  const content = useRoutes(routes);
  const auth = useAuth();
  useScrollReset();

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  // const theme = createTheme({
  //   direction: settings.direction,
  //   responsiveFontSizes: settings.responsiveFontSizes,
  //   roundedCorners: settings.roundedCorners,
  //   theme: settings.theme,
  // });

  return (
    <div className={'app'}>
   {!location.pathname.includes('authentication') && <Header />}
      <main className={'main'}>
        <SnackbarProvider
          dense
          maxSnack={3}
        >
          {auth.isInitialized ? content : <SplashScreen />}
        </SnackbarProvider>
      </main>
      <Footer />
    </div>

  );
};

export default App;
