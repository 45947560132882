import styles from './Inventory.module.scss';
import { Stack, Typography, Backdrop, CircularProgress } from '@mui/material';
import { tabsData } from './Data/tabsData';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Header } from './Header.jsx';
import { Overview } from './Overview.jsx';
import { Close } from '@mui/icons-material';
import { DefaultIconButton } from 'components/DefaultIconButton';
import api from 'lib/axios';
import { useLocation, useNavigate } from 'react-router';
import { PipeTallyAttachment } from './PipeTallyAttchment';
import { PipeTallyNotes } from './PipeTallyNotes';
export const PipeTallyDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const selectedDataId = location.state.id
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(tabsData[0].value);
    const [objPipeTally, setObjPipeTally] = useState(null);

    useEffect(() => {
        console.log("useEffect triggered");
        console.log("selectedDataId:", selectedDataId);
        getPipeTallyDetail();
    }, [selectedDataId]);

    function getPipeTallyDetail() {
        setIsLoading(true)
        api.get('/apis/Tallies/getDetailById/' + selectedDataId).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setObjPipeTally(resData)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    function handleClose() {
        navigate(-1)
    }

    return (
        <>
            <Stack className={styles.assets} backgroundColor='#F2F5F9'>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    spacing={8}
                    sx={{
                        backgroundColor: '#F2F5F9',
                        px: '30px',
                        // py: '3px',
                        '*': {
                            color: 'common.white',
                        },
                    }}
                >
                    <Typography fontWeight={700} color="#262B3F" fontSize={23}>Pipe Tally</Typography>
                    <Stack direction={'row'} spacing={3} component={'ul'} flexGrow={1}>
                        {tabsData.map((item) => (
                            <li
                                className={classNames(styles.assetTabItem, styles.assetTabItemLight, {
                                    [styles.assetTabItemActive]: activeTab === item.value,
                                })}
                                key={item.id}
                                onClick={() => setActiveTab(item.value)}
                            >
                                {item.title}
                            </li>
                        ))}
                    </Stack>
                    <DefaultIconButton
                        onClick={handleClose}
                        sx={{
                            backgroundColor: 'primary.main',
                            color: 'common.white',
                            '&:hover': {
                                backgroundColor: 'red.main',
                            },
                            height: 35,
                            width: 35,
                        }}
                        icon={<Close />}
                    />
                </Stack>
                {objPipeTally != null && <Header objPipeTally={objPipeTally} />}
                <Stack flexGrow={1} direction={'row'}>
                    {activeTab === 'pipe' &&  <Overview selectedDataId={selectedDataId} getData={getPipeTallyDetail} objPipeTally={objPipeTally} />}
                    {activeTab === 'attachments' && <PipeTallyAttachment pipeTallyID={selectedDataId}/>}
                    {activeTab === 'notes' && <PipeTallyNotes pipeTallyID={selectedDataId}/>}
                </Stack>
            </Stack>
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}