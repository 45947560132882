import { FormControl, Autocomplete, TextField } from '@mui/material';
import React from 'react';

export const DefaultSelect = ({ label, name, value, options, getOptionLabel, isOptionEqualToValue, sx, ...otherProps }) => {
    return (
        <FormControl variant='standard' sx={{ minWidth: '100%', ...sx }}>
            <Autocomplete
                {...otherProps}
                autoSelect
                value={value}
                options={options}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                onChange={(event, newValue) => {
                    otherProps.onChange && otherProps.onChange(event, newValue);
                }}
                style={{ width: '100%' }}
                renderInput={(params) => (
                    <TextField
                        {...otherProps}
                        {...params}
                        label={label}
                        name={name}
                        variant='standard'
                        onKeyDown={(e) => { if (e.key !== 'Tab') { e.preventDefault(); } }}
                        InputLabelProps={{
                            ...params.InputProps,
                            shrink: true,
                            readOnly: true,
                        }}
                        sx={{
                            width: '100%',
                            '.MuiFormLabel-root': {
                                fontSize: 15,
                                fontWeight: 700,
                                transform: 'unset',
                                color: 'grey.blue',
                                position: 'static',
                                '&.Mui-disabled': {
                                    color: 'primary.main',
                                },
                            },
                            '.MuiInput-input': {
                                py: '10px',
                                fontSize: 15,
                                '&::placeholder': {
                                    color: 'grey.blue',
                                    opacity: 1,
                                },
                            },
                            '.MuiInputBase-root': {
                                mt: label ? 2.5 : 0,
                                '&::before': {
                                    borderColor: 'grey.light',
                                },
                                '&.Mui-disabled': {
                                    '&::before': {
                                        display: 'none'
                                    }
                                },
                            },

                        }}

                    />
                )}
            />
        </FormControl>
    );
};
