import React, { Suspense, lazy } from 'react';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';

import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';

import { useNavigate, useLocation } from 'react-router-dom';
import { PipeTally } from './views/PipeTally';
import { PipeTallyDetails } from './views/PipeTallyDetail/PipeTallyDetails';



function Loadable(Component) {
  const InjectedNavigate = function (props) {
    const navigate = useNavigate(props);
    const location = useLocation(props);
    return (<Suspense fallback={<LoadingScreen />}>
      <Component {...props} navigate={navigate} location={location} />
    </Suspense>)
  };
  return InjectedNavigate;
}


const Login = Loadable(lazy(() => import('./views/Login/Login')));
const ForgotPassword = Loadable(lazy(() => import('./views/Forgot/Forgot')));
const ResetPassword = Loadable(lazy(() => import('./views/ResetPassword/ResetPassword')));
const ChangePassword = Loadable(lazy(() => import('./views/ChangePassword/ChangePassword')));
const EditProfile = Loadable(lazy(() => import('./views/EditProfile/EditProfile')));

const UserList = Loadable(lazy(() => import('./views/User/UserList')));
const UserManage = Loadable(lazy(() => import('./views/User/UserManage')));

const PasswordSecurityForm = Loadable(lazy(() => import('./views/Security/PasswordSecurityForm')));

const UserDetails = Loadable(lazy(() => import("./views/User/UserDetails")));
const Dashboard = Loadable(lazy(() => import("./views/Dashboard/Dashboard")));

const Setting = Loadable(lazy(() => import("./views/Settings/Settings")));
const Inventory = Loadable(lazy(() => import("./views/Inventory/Inventory")));
const InventoryManage = Loadable(lazy(() => import("./views/Inventory/InventoryData/InventoryData")));

const MapView = Loadable(lazy(() => import("./views/MapView/MapView")));
const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />
      },
      {
        path: 'reset-password',
        element: <ResetPassword />
      },
      {
        path: 'login-unguarded',
        element: <Login />
      }
    ]
  },
  {
    path: 'settings',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/settings',
        element: <Setting />
      },
    ]
  },
  {
    path: 'profile',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/profile/editProfile',
        element: <EditProfile />
      },
      {
        path: '/profile/changePassword',
        element: <ChangePassword />
      },
    ]
  },
  {
    path: 'inventory',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/inventory',
        element: <Inventory />
      },
      {
        path: '/inventory/manage',
        element: <InventoryManage />
      },
    ]
  },
  {
    path: 'map-view',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/map-view',
        element: <MapView />
      },
    ]
  },
  {
    path: 'users',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/users',
        element: <UserList />
      },
      {
        path: '/users/manage',
        element: <UserManage />
      },
      {
        path: '/users/details',
        element: <UserDetails />
      }
    ]
  },
  {
    path: 'pipe-tally',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/pipe-tally',
        element: <PipeTally />
      },
      {
        path: '/pipe-tally/manage',
        element: <PipeTallyDetails />
      },
    ]
  },
  // {
  //   path: 'loginlogs',
  //   element: (
  //     <AuthGuard>
  //       <DashboardLayout />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       path: '/loginlogs',
  //       element: <LoginLogs />
  //     },
  //   ]
  // },
  {
    path: 'passwordsecurity',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/passwordsecurity',
        element: <PasswordSecurityForm />
      },
    ]
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        // element: <FloorView />
        element: <Inventory />
      },
    ]
  },
  {
    path: '/',
    element: <Dashboard />,
    children: [
      {
        path: '/',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
      }
    ]
  }
];

export default routes;
