import { FormControl, MenuItem, Select } from '@mui/material';
import React from 'react';

export const DropDown = (props) => {
    const handleSelectChange = (event) => {
        props.onChange && props.onChange(event);
    };
    return (
        <FormControl variant='standard' sx={{ minWidth: '100%', ...props.sx }}>
            <Select
                {...props}
                sx={{
                    '&::before': {
                        borderColor: 'grey.light',
                    },
                    '.MuiSelect-select': {
                        py: '10px',
                        fontSize: 15,
                        fontWeight: 700,
                    },
                }}

                value={props.value}
                onChange={handleSelectChange}
             
            >
                 <MenuItem key={-1} value={-1}>All</MenuItem>
                {props.children}
            </Select>
        </FormControl>
    );
};
