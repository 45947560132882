export const tabsData = [
    {
        id: 1,
        title: 'Pipe',
        value: 'pipe',
    },
    {
        id: 2,
        title: 'Attachments',
        value: 'attachments',
    },
    {
        id: 3,
        title: 'Notes',
        value: 'notes',
    },
];
