import { Stack, Typography } from '@mui/material';
import React from 'react';
import { ColorStatus } from '../../../components/ColorStatus/index.js';
import { ArrowForwardIos } from '@mui/icons-material';

export const getHealth = (percentage) => {
    if (percentage > 99.9) {
        return 'AAA'
    } else if (percentage > 94.9 && percentage < 99.9) {
        return 'AA'
    } else if (percentage > 89.9 && percentage < 95) {
        return 'A'
    } else if (percentage > 79.9 && percentage < 90) {
        return 'EOL'
    } else {
        return 'Downgrade'
    }
}

export const getHealthColor = (percentage) => {
    if (percentage > 99.9) {
        return '#24D5AA'
    } else if (percentage > 94.9 && percentage < 99.9) {
        return '#0084FF'
    } else if (percentage > 89.9 && percentage < 95) {
        return '#FEBC2E'
    } else if (percentage > 79.9 && percentage < 90) {
        return '#FF3841'
    } else {
        return '#262B40'
    }
}


export const arrayStatus = [
    { id: "1", value: "In-Service" },
    { id: "2", value: "Inspection" },
    { id: "3", value: "In-Storage" },
]

export const columns = [
    { field: 'workOrder', headerName: 'Work Order', width: 130 },
    { field: 'customer', headerName: 'Customer', width: 130 },
    { field: 'description', headerName: 'Description', flex: 1 },
    { field: 'connection', headerName: 'Connection', flex: 1 },
    { field: 'qty', headerName: 'QTY', width: 110 },
    { field: 'footage', headerName: 'Footage', width: 110 },
    { field: 'po', headerName: 'PO#', width: 110 },
    { field: 'date', headerName: 'Date', width: 110 },
    {
        field: 'stringGrade',
        headerName: 'String Grade',
        width: 110,
        renderCell: ({ value }) => (
            <Stack
                width={'100%'}
                direction={'row'}
                justifyContent={'flex-end'}
                alignItems={'center'}
                spacing={3}
                pr={2}
                maxWidth={'150px'}
            >
                <ColorStatus code={value} />
                <ArrowForwardIos
                    sx={{
                        width: 13,
                        height: 13,
                    }}
                />

            </Stack>
        ),
    },
];

export const rows = [
    {
        id: 1,
        workOrder: 'EQT1000293',
        customer: 'EQT Corporation',
        description: '2.875” x 0.276”w 7.90 Lb/ft, P-110 SMLS R2 Tubing.',
        connection: 'TTS-6 Black 3.500” OD x 2.265” ID',
        qty: '5,222',
        footage: '10,235',
        po: '120047',
        date: '01/02/2023',
        stringGrade: 1,
    },
    {
        id: 2,
        workOrder: 'EQT1000293',
        customer: 'EQT Corporation',
        description: '2.875” x 0.276”w 7.90 Lb/ft, P-110 SMLS R2 Tubing.',
        connection: 'TTS-6 Black 3.500” OD x 2.265” ID',
        qty: '5,222',
        footage: '10,235',
        po: '120047',
        date: '01/02/2023',
        stringGrade: 1,
    },
    {
        id: 3,
        workOrder: 'EQT1000293',
        customer: 'EQT Corporation',
        description: '2.875” x 0.276”w 7.90 Lb/ft, P-110 SMLS R2 Tubing.',
        connection: 'TTS-6 Black 3.500” OD x 2.265” ID',
        qty: '5,222',
        footage: '10,235',
        po: '120047',
        date: '01/02/2023',
        stringGrade: 1,
    },
    {
        id: 4,
        workOrder: 'EQT1000293',
        customer: 'EQT Corporation',
        description: '2.875” x 0.276”w 7.90 Lb/ft, P-110 SMLS R2 Tubing.',
        connection: 'TTS-6 Black 3.500” OD x 2.265” ID',
        qty: '5,222',
        footage: '10,235',
        po: '120047',
        date: '01/02/2023',
        stringGrade: 1,
    },
    {
        id: 5,
        workOrder: 'EQT1000293',
        customer: 'EQT Corporation',
        description: '2.875” x 0.276”w 7.90 Lb/ft, P-110 SMLS R2 Tubing.',
        connection: 'TTS-6 Black 3.500” OD x 2.265” ID',
        qty: '5,222',
        footage: '10,235',
        po: '120047',
        date: '01/02/2023',
        stringGrade: 1,
    },
];
