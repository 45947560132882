import React, { useState } from "react";
import { Backdrop, Box, Card, CircularProgress, Grid, LinearProgress, Stack, Typography, } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import styles from './Inventory.module.scss';
import classNames from 'classnames';
import { DefaultButton } from "components/DefaultButton";
import moment from "moment/moment";
import { ColorStatus } from "components/ColorStatus";
import { DefaultDataGrid } from "components/DefaultDataGrid";
import { DefaultIconButton } from "components/DefaultIconButton";
import { Close } from '@mui/icons-material';
import { wallThicknessData } from "../Inventory/staticData/pipeListingData";
import { ScrollContent } from "components/ScrollContent";
import { ColorCodeStatus } from "components/ColorCodeStatus/ColorCodeStatus";
import { getHealth, getHealthColor } from "./Data/tableData";

export const PipeTallyDocumentAlert = (props) => {
    const tabsData = [
        {
            id: 1,
            title: 'Pipe',
            value: 'pipe',
        },
        {
            id: 2,
            title: 'Documentation',
            value: 'documentation',
        },
    ]
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(tabsData[0].value);
    const column = [
        {
            field: 'wallThickness', headerName: 'Wall Thickness', width: 200,
            renderCell: ({ value }) => (
                <Typography fontWeight={'bold'} fontSize={'13px'}>
                    {value}
                </Typography>
            ),
        },
        {
            field: 'date', headerName: 'Date updated', width: 200,
            renderCell: ({ value }) => (
                <Typography fontWeight={'bold'} fontSize={'13px'}>
                    {value ? moment(value).format("MM-DD-YYYY hh:mma") : "-"}
                </Typography>
            ),
        },
        {
            field: 'percentage',
            headerName: 'Percentage',
            flex: 1,
            renderCell: ({ value }) => (
                <Stack
                    width={'100%'}
                    direction={'row'}
                    alignItems={'center'}
                    spacing={1}
                    pr={2}
                >
                    <Typography fontSize={13} width={80}> {getHealth(value) + "%"}</Typography>
                    <Box
                        sx={{
                            width: 10,
                            height: 10,
                            borderRadius: '50%',
                            backgroundColor: getHealthColor(value),
                        }}
                    />
                </Stack>
            ),
        },
    ]
    const { objPipe, connection } = props

    function handleClose() {
        props.updateConfirmAlert(false);
    };

    return (
        <>
            <Dialog
                open
                fullWidth
                onClose={() => props.updateConfirmAlert(false)}
                aria-labelledby="alert-dialog-title"
                PaperProps={{
                    style: {
                        minWidth: '60vw',
                        minHeight: '45vw',
                        maxHeight: '45vw',
                        border: '1px #1F243B solid',
                        borderRadius: '15px',
                    },
                }}
            >
                <Card sx={{ p: 2, boxShadow: 'none' }}>
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        spacing={8}
                    >
                        <Stack direction={'row'} spacing={3} component={'ul'} flexGrow={1} >
                            {tabsData.map((item) => (
                                <li
                                    className={classNames(styles.assetTabItem, styles.assetTabItemLight, {
                                        [styles.assetTabItemActive]: activeTab === item.value,
                                    })}
                                    key={item.id}
                                    onClick={() => setActiveTab(item.value)}
                                >
                                    {item.title}
                                </li>
                            ))}
                        </Stack>
                        <DefaultIconButton
                            onClick={handleClose}
                            sx={{
                                backgroundColor: 'primary.main',
                                color: 'common.white',
                                '&:hover': {
                                    backgroundColor: 'red.main',
                                },
                                height: 30,
                                width: 30,
                            }}
                            icon={<Close />}
                        />
                    </Stack>
                    {activeTab === 'documentation' && <Grid container spacing={2} mt={1}>
                        <Grid item xs={12}>
                            <Typography mb={2} style={{ fontWeight: 700, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '13px' }}>MATERIAL TEST REPORT</Typography>
                            <Typography mb={2} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: '#2F73EA', fontSize: '13px', cursor: 'pointer' }}
                                onClick={(e) => {
                                    window.open(objPipe.materialTestReportPath, '_blank');
                                }}>
                                {objPipe.materialTestReport && objPipe.materialTestReport != "" ? objPipe.materialTestReport : "-"}
                            </Typography>
                            <Box borderBottom="1px solid #D5D5D5" width='200px' />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography mb={2} style={{ fontWeight: 700, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '13px' }}>NON-DESTRUCTIVE EVALUATION REPORT</Typography>
                            <Typography mb={2} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: '#2F73EA', fontSize: '13px', cursor: 'pointer' }}
                                onClick={(e) => {
                                    window.open(objPipe.nonDestructivePath, '_blank');
                                }}>
                                {objPipe.nonDestructiveEvaluationReport && objPipe.nonDestructiveEvaluationReport != "" ? objPipe.nonDestructiveEvaluationReport : "-"}
                            </Typography>
                            <Box borderBottom="1px solid #D5D5D5" width='200px' />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography mb={2} style={{ fontWeight: 700, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '13px' }}>CONNECTION DATASHEET</Typography>
                            <Typography mb={2} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: '#2F73EA', fontSize: '13px', cursor: 'pointer' }}
                                onClick={(e) => {
                                    window.open(objPipe.connectionDatasheetPath, '_blank');
                                }}>
                                {objPipe.connectionDatasheet && objPipe.connectionDatasheet != "" ? objPipe.connectionDatasheet : "-"}
                            </Typography>
                            <Box borderBottom="1px solid #D5D5D5" width='200px' />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography mb={2} style={{ fontWeight: 700, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '13px' }}>RIG RUNNING PROCEDURE</Typography>
                            <Typography mb={2} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: '#2F73EA', fontSize: '13px', cursor: 'pointer' }}
                                onClick={(e) => {
                                    window.open(objPipe.rigRunningProcedurePath, '_blank');
                                }}>
                                {objPipe.rigRunningProcedure && objPipe.rigRunningProcedure != "" ? objPipe.rigRunningProcedure : "-"}
                            </Typography>
                        </Grid>
                    </Grid>}
                    {activeTab === 'pipe' &&
                        <Stack direction="column" style={{ height: '100%', }} flexGrow={1} backgroundColor='red'>
                            <Stack
                                direction={'row'}
                                spacing={2}
                                justifyContent={'space-between'}
                                marginBottom={2}
                            >
                                <Grid container spacing={2} xs={8}>
                                    <Grid item xs={6}>
                                        <Typography mb={1} style={{ fontWeight: 700, }}>Connection</Typography>
                                        <Typography style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{connection ? connection : "-"}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography mb={1} style={{ fontWeight: 700, }}>Serial 1</Typography>
                                        <Typography style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{objPipe.serialId1 && objPipe.serialId1 != "" ? objPipe.serialId1 : "-"}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography mb={1} style={{ fontWeight: 700, }}>HEAT</Typography>
                                        <Typography style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{objPipe.heat && objPipe.heat != "" ? objPipe.heat : "-"}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography mb={1} style={{ fontWeight: 700, }}>LOT</Typography>
                                        <Typography style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{objPipe.lot && objPipe.lot != "" ? objPipe.lot : "-"}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography mb={1} style={{ fontWeight: 700, }}>Serial 2</Typography>
                                        <Typography style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{objPipe.serialId2 && objPipe.serialId2 != "" ? objPipe.serialId2 : "-"}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography mb={1} style={{ fontWeight: 700, }}>JOINT</Typography>
                                        <Typography style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{objPipe.jnt && objPipe.jnt != "" ? objPipe.jnt : "-"}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography mb={1} style={{ fontWeight: 700, }}>LENGTH</Typography>
                                        <Typography style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{objPipe.length && objPipe.length != "" ? objPipe.length : "-"}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography mb={1} style={{ fontWeight: 700, }}>Serial 3</Typography>
                                        <Typography style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{objPipe.serialId3 && objPipe.serialId3 != "" ? objPipe.serialId3 : "-"}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} xs={4}>
                                    <Grid item xs={12}>
                                        <Stack direction={'row'} spacing={1} mb={1} alignItems={'center'} >
                                            <Typography style={{ fontWeight: 700, whiteSpace: 'nowrap', }}>Health Rating</Typography>
                                            <Box
                                                sx={{
                                                    width: 10,
                                                    height: 10,
                                                    borderRadius: '50%',
                                                    backgroundColor: getHealthColor(objPipe.percentage),
                                                }}
                                            />
                                        </Stack>
                                        <Typography fontSize={25} fontWeight={700} marginBottom={4}>
                                            {getHealth(objPipe.percentage)}
                                        </Typography>
                                        {/* <DefaultButton
                                            sx={{
                                                backgroundColor: 'primary.main',
                                                color: 'common.white',
                                                '&:hover': {
                                                    backgroundColor: 'primary.main',
                                                },
                                                height: 30,
                                                width: 120,
                                                marginBottom: 5
                                            }}
                                        >
                                            Prime
                                        </DefaultButton> */}
                                        <Stack direction={'row'} spacing={1}>
                                            <Typography mb={1} style={{ fontWeight: 700, whiteSpace: 'nowrap', }}>Updated:</Typography>
                                            <Typography style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{objPipe.updatedAt && objPipe.updatedAt != "" ? moment(objPipe.updatedAt).format('MM/DD/YY hh:mm a') : "-"}</Typography>
                                        </Stack>

                                    </Grid>
                                </Grid>
                            </Stack>
                            <Box height='25vw'>
                                <DefaultDataGrid columns={column} rows={objPipe.arrayWallThickness ?
                                    objPipe.arrayWallThickness.map((item, index) => ({
                                        ...item,
                                        id: index + 1
                                    })) : []}
                                    onRowClick={(e) => { }}
                                />
                            </Box>
                        </Stack>}
                </Card>
                <Backdrop style={{ zIndex: 1 }} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

            </Dialog>
        </>
    )
}