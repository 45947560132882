import styles from './index.module.scss';
import {
    Box, Grid, Stack, Typography, Backdrop,
    CircularProgress
} from '@mui/material';
import { Title } from '../../components/Title/index.js';
import { DefaultInput } from '../../components/DefaultInput/index.js';
import { ScrollContent } from '../../components/ScrollContent/index.js';
import { DefaultButton } from '../../components/DefaultButton/index.js';
import { DefaultSelect } from '../../components/DefaultSelect/index.js';
import React, { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import api from '../../lib/axios';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ConfirmAlert } from '../../customControls/confirmAlert';
import { useNavigate } from 'react-router-dom';
import { arrayType, arrayStatus } from '../PipeTally/Data/PipeTallyData.jsx';

export const PipeTallyNew = ({ selectedDataId, closeDetails }) => {
    const navigate = useNavigate();
    const [arrayPipeProfile, setArrayPipeProfile] = useState([]);
    const [arrayLocation, setArrayLocation] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState("");
    const [selectedType, setSelectedType] = useState(null);
    const [purchaseOrder, setPurchaseOrder] = useState("");
    const [customer, setCustomer] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [targetedLength, setTargetedLength] = useState("");
    const [selectedPipeProfileName, setSelectedPipeProfileName] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [workOrder, setWorkOrder] = useState("");
    const [padName, setPadName] = useState("");
    const [rigNumber, setRigNumber] = useState("");
    const [wellNumber, setWellNumber] = useState("");
    const [kop30, setKop30] = useState("");
    const [kop60, setKop60] = useState("");
    const [kop90, setKop90] = useState("");
    const [deleteMessage, setDeleteMessage] = useState("");
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);
    const [tallyID, setTallyID] = useState("");

    useEffect(() => {
        getPipeTallyDetail();
    }, [selectedDataId]);

    function getPipeTallyDetail() {
        setIsLoading(true)
        api.get('/apis/Tallies/TallyDetail').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayPipeProfile(resData.arrayPipeProfile)
                setArrayLocation(resData.arraySite)
                if (selectedDataId != null) {
                    getPipeTallyData(resData.arraySite, resData.arrayPipeProfile)
                } else {
                    var selectedStatus = arrayStatus.filter(status => status.id == "3")
                    if (selectedStatus.length > 0) {
                        selectedStatus = selectedStatus[0]
                    } else {
                        selectedStatus = null
                    }
                    setSelectedStatus(selectedStatus)
                    setIsLoading(false)
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    function getPipeTallyData(arrayLocation, arrayPipeProfile) {
        // setIsLoading(true)
        api.get('/apis/Tallies/getDetailById/' + selectedDataId).then((res) => {
            console.log("DATA", res.data.data)
            if (res.data.success) {
                var resData = res.data.data
                var selectedStatus = arrayStatus.filter(status => status.id == resData.status)
                if (selectedStatus.length > 0) {
                    selectedStatus = selectedStatus[0]
                } else {
                    selectedStatus = null
                }
                var selectedLocation = arrayLocation.filter(location => location._id == resData.locationID)
                if (selectedLocation.length > 0) {
                    selectedLocation = selectedLocation[0]
                } else {
                    selectedLocation = null
                }
                var selectedPipeProfile = arrayPipeProfile.filter(pipeProfile => pipeProfile._id == resData.pipeProfileID)
                if (selectedPipeProfile.length > 0) {
                    selectedPipeProfile = selectedPipeProfile[0]
                } else {
                    selectedPipeProfile = null
                }
                var selectedType = arrayType.filter(profileName => profileName.id == resData.type)
                if (selectedType.length > 0) {
                    selectedType = selectedType[0]
                } else {
                    selectedType = null
                }
                setSelectedLocation(selectedLocation)
                setSelectedPipeProfileName(selectedPipeProfile)
                setSelectedStatus(selectedStatus)
                setCustomer(resData.customer)
                setSelectedType(selectedType)
                setTitle(resData.title)
                setPurchaseOrder(resData.purchaseOrder)
                setTargetedLength(resData.targetedLength)
                setTallyID(resData.tallyNo)
                setWorkOrder(resData.workOrder)
                setPadName(resData.padName)
                setRigNumber(resData.rigNumber)
                setWellNumber(resData.wellNumber)
                setKop30(resData.kop30)
                setKop60(resData.kop60)
                setKop90(resData.kop90)
                setIsLoading(false)
            }

        }).catch(function (error) {
            console.log(error);
        });
    }

    async function apiCalling(values) {
        var data = {
            title: values.title,
            type: values.selectedType.id,
            purchaseOrder: values.purchaseOrder,
            status: values.selectedStatus.id,
            pipeProfileID: values.selectedPipeProfileName._id,
            locationID: values.selectedLocation._id,
            customer: values.customer,
            targetedLength: values.targetedLength,
            workOrder: values.workOrder,
            padName: values.padName,
            rigNumber: values.rigNumber,
            wellNumber: values.wellNumber,
            kop30: values.kop30,
            kop60: values.kop60,
            kop90: values.kop90,
        }
        if (selectedDataId != null) {
            return await api.put('/apis/Tallies/updateById/' + selectedDataId, data)
        } else {
            return await api.post('/apis/Tallies/add', data)
        }
    }

    function deletePipeTally() {
        setIsLoading(true)
        api.delete('/apis/Tallies/deleteById/' + selectedDataId).then((res) => {
            if (res.data.success) {
                enqueueSnackbar("Pipe tally deleted", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                navigate(-1)
            } else {
                setDeleteConfirmationShouldOpen(false)
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const updateConfirmAlert = (value) => {
        if (value) {
            deletePipeTally()
        } else {
            setDeleteConfirmationShouldOpen(false)
        }
    }

    return (
        <>
            <Stack
                overflowY={'auto'}
                minWidth={'700px'}
                height={'750px'}
                maxHeight={'calc(100vh - 100px)'}
                maxWidth={'700px'}
                position={'fixed'}
                sx={{
                    borderLeft: '1px solid black',
                    borderColor: 'grey.light',
                    backgroundColor: 'common.white',
                }}
                className={styles.settingsDetails}
            >
                <ScrollContent>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            submit: null,
                            title: title,
                            selectedType: selectedType,
                            purchaseOrder: purchaseOrder,
                            selectedStatus: selectedStatus,
                            selectedPipeProfileName: selectedPipeProfileName,
                            selectedLocation: selectedLocation,
                            customer: customer,
                            targetedLength: targetedLength,
                            workOrder: workOrder,
                            padName: padName,
                            rigNumber: rigNumber,
                            wellNumber: wellNumber,
                            kop30: kop30,
                            kop60: kop60,
                            kop90: kop90,
                        }}
                        validationSchema={Yup.object().shape({
                            title: Yup.string().required('Title is required'),
                            selectedType: Yup.object().required('Pipe type is required'),
                            purchaseOrder: Yup.string().required('Purchase order is required'),
                            targetedLength: Yup.string().required('Targeted length is required'),
                            selectedStatus: Yup.object().required('Status is required'),
                            selectedPipeProfileName: Yup.object().required('Pipe profile name is required'),
                            selectedLocation: Yup.object().required('Location is required'),
                            customer: Yup.string().required('Customer is required'),
                            workOrder: Yup.string().required('Work Order is required'),
                            padName: Yup.string().required('Pad Name is required'),
                            rigNumber: Yup.string().required('Rig Number is required'),
                            wellNumber: Yup.string().required('Well Number is required'),
                            kop30: Yup.string().required('Kop 30 is required'),
                            kop60: Yup.string().required('Kop 60 is required'),
                            kop90: Yup.string().required('Kop 90 is required'),
                        })}
                        onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                            console.log("onsubmit")
                            try {
                                setIsLoading(true)
                                setTitle(values.title)
                                setSelectedType(values.selectedType)
                                setPurchaseOrder(values.purchaseOrder)
                                setSelectedStatus(values.selectedStatus)
                                setSelectedPipeProfileName(values.selectedPipeProfileName)
                                setSelectedLocation(values.selectedLocation)
                                setCustomer(values.customer)
                                setTargetedLength(values.targetedLength)
                                setWorkOrder(values.workOrder)
                                setPadName(values.padName)
                                setRigNumber(values.rigNumber)
                                setWellNumber(values.wellNumber)
                                setKop30(values.kop30)
                                setKop60(values.kop60)
                                setKop90(values.kop90)
                                var data = await apiCalling(values)

                                setIsLoading(false)
                                if (data !== undefined && data.data.success) {
                                    console.log("IF")
                                    resetForm();
                                    setStatus({ success: true });
                                    setSubmitting(false);

                                    enqueueSnackbar('Pipe tally ' + (selectedDataId == null ? "added" : "updated"), {
                                        anchorOrigin: {
                                            horizontal: 'right',
                                            vertical: 'top'
                                        },
                                        variant: 'success'
                                    });
                                    closeDetails(true)
                                } else {
                                    console.log("ELSE")
                                    setStatus({ success: false });
                                    setErrors({ submit: data.data.message });
                                    setSubmitting(false);
                                    enqueueSnackbar(data.data.message, {
                                        anchorOrigin: {
                                            horizontal: 'right',
                                            vertical: 'top'
                                        },
                                        variant: 'error'
                                    });
                                }
                            } catch (err) {
                                console.log("error12", err);
                                setIsLoading(false)
                                setStatus({ success: false });
                                setErrors({ submit: err.message });
                                setSubmitting(false);
                            }

                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                            <form
                                onSubmit={handleSubmit}
                                style={{ height: "100%" }}
                            >
                                <Stack p={'35px 50px'} spacing={4}>
                                    <Title>Details</Title>
                                    <Box>
                                        <Grid container spacing={4}>
                                            <Grid item xs={8}>
                                                <DefaultInput
                                                    label={'Title'}
                                                    placeholder={'E.g. xyz'}
                                                    value={values.title}
                                                    name={"title"}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    error={Boolean(touched.title && errors.title)}
                                                    helperText={touched.title && errors.title}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <DefaultSelect
                                                    onBlur={handleBlur}
                                                    onChange={(event, newValue) => {
                                                        values.selectedType = newValue
                                                        console.log("st", newValue)
                                                    }}
                                                    helperText={touched.selectedType && errors.selectedType && <p>Select Category</p>}
                                                    error={Boolean(touched.selectedType && errors.selectedType)}
                                                    value={values.selectedType}
                                                    options={arrayType}
                                                    getOptionLabel={(option) => option.value}
                                                    isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                                    name="selectedType"
                                                    label="Type"
                                                    placeholder="Select Type"
                                                ></DefaultSelect>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <DefaultInput
                                                    label={'Purchase Order'}
                                                    placeholder={'E.g. 123'}
                                                    value={values.purchaseOrder}
                                                    name={"purchaseOrder"}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    error={Boolean(touched.purchaseOrder && errors.purchaseOrder)}
                                                    helperText={touched.purchaseOrder && errors.purchaseOrder}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <DefaultInput
                                                    label={'Customer'}
                                                    placeholder={'E.g. xyz'}
                                                    value={values.customer}
                                                    name={"customer"}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    error={Boolean(touched.customer && errors.customer)}
                                                    helperText={touched.customer && errors.customer}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <DefaultSelect
                                                    onBlur={handleBlur}
                                                    onChange={(event, newValue) => {
                                                        values.selectedLocation = newValue
                                                        console.log("st", newValue)
                                                    }}
                                                    helperText={touched.selectedLocation && errors.selectedLocation && <p>Select Category</p>}
                                                    error={Boolean(touched.selectedLocation && errors.selectedLocation)}
                                                    value={values.selectedLocation}
                                                    options={arrayLocation}
                                                    getOptionLabel={(option) => option.siteName}
                                                    isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                                    name="selectedLocation"
                                                    label="Site Location"
                                                    placeholder="Select Site Location"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <DefaultInput
                                                    label={'Targeted Length'}
                                                    placeholder={'E.g. 123'}
                                                    value={values.targetedLength}
                                                    name={"targetedLength"}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    error={Boolean(touched.targetedLength && errors.targetedLength)}
                                                    helperText={touched.targetedLength && errors.targetedLength}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <DefaultSelect
                                                    onBlur={handleBlur}
                                                    onChange={(event, newValue) => {
                                                        values.selectedPipeProfileName = newValue
                                                        console.log("st", newValue)
                                                    }}
                                                    helperText={touched.selectedPipeProfileName && errors.selectedPipeProfileName && <p>Select pipe profile name</p>}
                                                    error={Boolean(touched.selectedPipeProfileName && errors.selectedPipeProfileName)}
                                                    value={values.selectedPipeProfileName}
                                                    options={arrayPipeProfile}
                                                    getOptionLabel={(option) => option.description}
                                                    isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                                    name="selectedPipeProfileName"
                                                    label="Pipe Profile Name"
                                                    placeholder="Select Pipe Profile Name"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <DefaultSelect
                                                    readOnly={selectedDataId == null}
                                                    onBlur={handleBlur}
                                                    onChange={(event, newValue) => {
                                                        values.selectedStatus = newValue
                                                        console.log("st", newValue)
                                                    }}
                                                    helperText={touched.selectedStatus && errors.selectedStatus && <p>Select status</p>}
                                                    error={Boolean(touched.selectedStatus && errors.selectedStatus)}
                                                    value={values.selectedStatus}
                                                    options={arrayStatus.filter(status => status.id != "3")}
                                                    getOptionLabel={(option) => option.value}
                                                    isOptionEqualToValue={(option, value) => value != null ? option._id === value._id : ""}
                                                    label='Status'
                                                    name="selectedStatus"
                                                    placeholder="Select Status"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <DefaultInput
                                                    label={'Work Order'}
                                                    placeholder={'E.g. 123'}
                                                    value={values.workOrder}
                                                    name={"workOrder"}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    error={Boolean(touched.workOrder && errors.workOrder)}
                                                    helperText={touched.workOrder && errors.workOrder}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <DefaultInput
                                                    label={'Pad Name'}
                                                    placeholder={'E.g. xyz'}
                                                    value={values.padName}
                                                    name={"padName"}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    error={Boolean(touched.padName && errors.padName)}
                                                    helperText={touched.padName && errors.padName}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <DefaultInput
                                                    label={'Rig Number'}
                                                    placeholder={'E.g. 123'}
                                                    value={values.rigNumber}
                                                    name={"rigNumber"}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    error={Boolean(touched.rigNumber && errors.rigNumber)}
                                                    helperText={touched.rigNumber && errors.rigNumber}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <DefaultInput
                                                    label={'Well Number'}
                                                    placeholder={'E.g. 123'}
                                                    value={values.wellNumber}
                                                    name={"wellNumber"}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    error={Boolean(touched.wellNumber && errors.wellNumber)}
                                                    helperText={touched.wellNumber && errors.wellNumber}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <DefaultInput
                                                    label={'Kop 30'}
                                                    placeholder={'E.g. 123'}
                                                    value={values.kop30}
                                                    name={"kop30"}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    error={Boolean(touched.kop30 && errors.kop30)}
                                                    helperText={touched.kop30 && errors.kop30}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <DefaultInput
                                                    label={'Kop 60'}
                                                    placeholder={'E.g. 123'}
                                                    value={values.kop60}
                                                    name={"kop60"}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    error={Boolean(touched.kop60 && errors.kop60)}
                                                    helperText={touched.kop60 && errors.kop60}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <DefaultInput
                                                    label={'Kop 90'}
                                                    placeholder={'E.g. 123'}
                                                    value={values.kop90}
                                                    name={"kop90"}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    error={Boolean(touched.kop90 && errors.kop90)}
                                                    helperText={touched.kop90 && errors.kop90}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Stack>
                                <Stack
                                    p={'35px 50px'}
                                    direction={'row'}
                                    justifyContent={'space-between'}
                                    mt={'auto'}
                                >
                                    {selectedDataId != null ? <DefaultButton
                                        onClick={(e) => {
                                            setDeleteMessage("Are you sure you want to delete?")
                                            setDeleteConfirmationShouldOpen(true)
                                        }}
                                    >
                                        Delete
                                    </DefaultButton> : <Box> </Box>}
                                    <Stack direction={'row'} spacing={2}>
                                        <DefaultButton
                                            sx={{ color: 'secondary.main' }}
                                            onClick={closeDetails}
                                        >
                                            Cancel
                                        </DefaultButton>
                                        <DefaultButton
                                            variant={'contained'}
                                            disabled={isSubmitting}
                                            type="submit"
                                        >
                                            {selectedDataId == null ? "Add" : "Update"}
                                        </DefaultButton>
                                    </Stack>
                                </Stack>
                                {
                                    deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={deleteMessage}
                                        updateConfirmAlert={(e, value) => {
                                            console.log(e, value)
                                            setDeleteConfirmationShouldOpen(false)
                                            updateConfirmAlert(value)
                                        }} />)
                                }
                                <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                            </form>
                        )}
                    </Formik>
                </ScrollContent>
            </Stack >
        </>
    )
}
