export const getStatusColor = (code) => {
    switch (code) {
        case 1:
            return 'green.main';
        case 2:
            return 'orange.main';
        case 3:
            return 'red.main';
        default:
            return 'green.main';
    }
};

export const getPipeTallyStatusColor = (code) => {
    switch (code) {
        case '0':
            return '#0084FF';
        case '1':
            return '#FEBC2E';
        case '2':
            return '#24D5AA';
        case '3':
            return '#262B40';
        default:
            return '#24D5AA';
    }
};

export const getStatusText = (code) => {
    switch (code) {
        case 1:
            return 'Online';
        case 2:
            return 'Preparing';
        case 3:
            return 'Offline';
        default:
            return 'Online';
    }
};
